import * as React from "react"
import Seo from "../components/seo"

import icon1 from "../../images/pages/challenge/icon1@2x.png"
import icon2 from "../../images/pages/challenge/icon2@2x.png"
import icon3 from "../../images/pages/challenge/icon3@2x.png"
import icon4 from "../../images/pages/challenge/icon4@2x.png"
import icon5 from "../../images/pages/challenge/icon5@2x.png"

const ChallengePage = () => {
  return (
    <section id="challenge">
      <Seo
        title="The Challenge"
        description="High school graduation rates for students of color & low-income lag behind. Even one teen dropout is one too many when you consider the impact on communities"
      />
      <div className="one half-pic-right">
        <div className="full-screen">
          <div className="left">
            <div className="content">
              <h2>The Challenge</h2>
              <p>
                Every year, over 1.2 million students drop out of high school in
                the United States alone. That’s a student every 26 seconds – or
                7,000 a day.
              </p>
              <p>
                Even though graduation rates are improving, students of color,
                at risk and from low-income households are still falling behind
                their more affluent peers.
              </p>
              <p>
                We believe even one student failing is too many when you
                consider the lost potential and negative impact on communities.
              </p>
            </div>
          </div>
          <div className="right"></div>
        </div>
        <div className="mobile">
          <div className="content">
            <h2>The Challenge</h2>
            <p>
              Every year, over 1.2 million students drop out of high school in
              the United States alone. That’s a student every 26 seconds – or
              7,000 a day.
            </p>
            <p>
              Even though graduation rates are improving, students of color, at
              risk and from low-income households are still falling behind their
              more affluent peers.
            </p>
            <p>
              We believe even one student failing is too many when you consider
              the lost potential and negative impact on communities.
            </p>
          </div>
        </div>
      </div>
      <div className="two smaller-center-text">
        <div className="container">
          <div className="content">
            <p>
              There are 4.5 million opportunity youth – 16-24-year-olds who are
              not in school and don’t have a job.
            </p>
          </div>
        </div>
      </div>
      <div className="three smaller-text-center">
        <div className="container">
          <div className="content">
            <p>
              There are about 500K Opportunity <br /> Youth in California
            </p>
            <p>1 in 4 Americans drop out </p>
            <p>60% of teen moms drop out </p>
            <p>1/4 of kids in poverty drop out </p>
          </div>
        </div>
      </div>
      <div className="four half-pic-left">
        <div className="full-screen">
          <div className="left"></div>
          <div className="right">
            <div className="content">
              <p>
                A high school dropout will earn $200,000 less than a high school
                graduate over his lifetime. And almost a million dollars less
                than a college graduate.
              </p>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="content">
            <p>
              A high school dropout will earn $200,000 less than a high school
              graduate over his lifetime. And almost a million dollars less than
              a college graduate.
            </p>
          </div>
        </div>
      </div>
      <div className="five">
        <div className="container">
          <div className="content">
            <div className="top">
              <div className="item">
                <img src={icon1} alt="" />
                <p>
                  In the U.S., high school dropouts commit about 75% of crimes.
                </p>
              </div>
              <div className="item">
                <img src={icon2} alt="" />
                <p>6.1% of young people 16-24 years old have dropped out.</p>
              </div>
              <div className="item">
                <img src={icon3} alt="" />
                <p>
                  A high-school dropout is ineligible for 90% of jobs in
                  America.
                </p>
              </div>
            </div>
            <div className="bottom">
              <div className="item">
                <img src={icon4} alt="" />
                <p>
                  31% of young adults without a high school diploma live in
                  poverty.
                </p>
              </div>
              <div className="item">
                <img src={icon5} alt="" />
                <p>40% of female dropouts give birth before the age of 20</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChallengePage
